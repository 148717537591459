
.navbar-custom {
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.head{
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-custom .navbar-brand {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
}

.navbar-custom .navbar-brand img {
  margin-right: 10px;
}

.navbar-custom .navbar-brand .teeth-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  fill: #00b4d8;
  transition: transform 0.3s ease;
}

.navbar-custom .navbar-brand:hover .teeth-icon {
  transform: rotate(20deg);
}

.navbar-custom .nav-link {
  color: black;
  font-size: 18px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;  
  flex-direction: column;
  text-align: center;
  transition: color 0.3s ease, transform 0.3s ease;
  font-weight: 500;
 
}

.navbar-custom .nav-link:hover {
  color: #00b4d8;
  transform: scale(1.1);
}

.navbar-custom .nav-link:after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #12A19A;
  transition: width 0.3s;
}

.navbar-custom .nav-link:hover:after {
  width: 100%;
}

.navbar-custom .phone-link {
  color: black;
  font-weight: bold;
  transition: color 0.3s ease, transform 0.3s ease;

  
 
}

.navbar-custom .phone-link:hover {
  color: #12A19A;
  transform: scale(1.1);
}

.navbar-custom .phone-link .fa-phone {
  margin-right: 5px;
}

.navbar-custom .social-icons {
  display: flex;
  align-items: center;
  justify-content : flex-end;

  width: 100%;
  margin-top: 10px;
}

.navbar-custom .social-link {
  font-size: 30px;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.navbar-custom .social-link:hover {
  transform: scale(1.1);
}

