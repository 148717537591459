.about-section {
  background: #ffffff; 
  color: #000000; 
  padding: 4rem 0;
}

.text-container {
  background: #ffffff; 
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid #dcdcdc; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-section h1, .about-section h2 {
  color: #000000;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.about-section p {
  color: #333333;
  font-size: 1.1rem;
  line-height: 1.6;
}

.about-section .card {
  background-color: #ffffff;
  border: 1px solid #dcdcdc; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  border-radius: 8px;
  color: #000000; 
}

.about-section .card .list-group-item {
  border: none;
  padding: 1rem 1.25rem;
  background-color: #ffffff;
  color: #000000;
}

.about-section .accordion-button {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.about-section .accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #000000;
}

.about-section .accordion-body {
  color: #333333;
  background-color: #f9f9f9;
  border-top: 1px solid #dcdcdc;
}

.about-section h1, .about-section h2, .about-section p, .accordion-button {
  transition: all 0.3s ease;
}

.about-section h1:hover, .about-section h2:hover, .accordion-button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

  
.whatsapp-link {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  color: #25D366;
  font-size: 50px;
  text-decoration: none;
}

.whatsapp-icon {
  font-size: 50px;
}


/* Add this to your existing About.css */

.meet-doctor {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 4rem;
}

.meet-doctor h2 {
  color: #000000;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.meet-doctor p {
  color: #333333;
  font-size: 1.1rem;
  line-height: 1.6;
}
