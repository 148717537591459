.services-section {
  background-color: #f8f9fa; /* Light grey background */
 
}

.services-section h2 {
  color: #343a40; /* Dark grey color for heading */
}

.services-section .card {
  transition: transform 0.3s, box-shadow 0.3s;
  border: 1px solid #ddd; /* Light grey border */
  border-radius: 8px; /* Rounded corners */
  position: relative; /* To position the popup */
}

.services-section .card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.services-section .card-body {
  background-color: #ffffff; /* White background for card body */
  color: #343a40; /* Dark grey color for text */
}

.services-section .card-title {
  font-weight: bold;
}

.services-section .card-text {
  font-size: 0.9rem;
}

.services-section a {
   /* Bootstrap primary color */
  text-decoration: none;
  font-weight: bold;
}

.services-section a:hover {
  text-decoration: underline;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  max-width: 800px;
  background: rgba(255, 255, 255, 0.9); /* White transparent background */
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  color: black; /* Black text color */
}

.popup-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.popup h3 {
  margin-top: 0;
  color: black; /* Black text color */
}

.popup p {
  margin: 1rem 0;
  font-size: 1rem;
  text-align: center;
  color: black; /* Black text color */
}

.whatsapp-link {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  color: #1e8a46;
  font-size: 50px;
  text-decoration: none;
}

.whatsapp-icon {
  font-size: 50px;
}
