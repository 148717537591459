.home-section {
  position: relative;
  overflow: hidden;
  padding: 2rem 0;
  min-height: 100vh;
}

/* .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../images2/background dental.jpg'); 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
} */


.background-image {
  position: fixed; /* Use fixed to keep the background fixed while scrolling */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../images2/Background.jpg'); /* Path to your background image */
  background-size: cover; /* Ensures the image covers the entire container */
  background-repeat: no-repeat; /* Prevents repeating of the image */
  background-position: center; /* Centers the background image */
  background-attachment: fixed; /* Keeps the background image fixed during scroll */
  z-index: -1; /* Places the background behind other elements */
}


.services-section {
  position: relative;
  z-index: 1;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background for content sections */
  padding: 4rem 0;
  margin-top: 4rem;
}

.services-section .card {
  transition: transform 0.3s;
}

.services-section .card:hover {
  transform: translateY(-10px);
}

.testimonials-section {
  position: relative;
  padding: 4rem 0;
  z-index: 1;
}

.heading a {
  text-decoration: none;
  color: #007bff; /* Bright color */
  text-shadow: 1px 1px 2px rgba(0,0,0,0.2); /* Subtle shadow for depth */
  font-size: 2.5rem; /* Slightly larger font */
  font-weight: bold;
  display: inline-block;
  transition: color 0.3s, transform 0.3s;
}

.heading a:hover {
  color: #0056b3; /* Darker shade on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.carousel-control-prev,
.carousel-control-next {
  width: auto;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 5;
}

.carousel-control-prev {
  left: 0;
  margin-left: -200px;
  margin-top: 100px;
}

.carousel-control-next {
  right: 0;
  margin-right: -200px;
  margin-top: 100px;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonial-card {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: #333;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  margin: 10px;
  width: 300px; /* Fixed width */
  height: 200px; /* Fixed height */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Single line text with ellipsis */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.testimonial-card p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Single line text with ellipsis */
  display: inline-block;
  width: 100%;
}

.testimonial-card:hover p {
  white-space: normal;
  height: auto;
  overflow: visible;
  background: rgba(255,255,255,0.9);
  padding: 10px;
  border-radius: 5px;
}

.testimonial-rating {
  margin-bottom: 10px;
}

.star-icon {
  color: #ffd700;
}

.google-icon {
  font-size: 20px;
  color: #7142f4; /* Google logo color */
  margin-top: 10px;
  height: 30px;
  width: 30px;
}

.whatsapp-link {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  color: #25D366;
  font-size: 50px;
  text-decoration: none;
}

.whatsapp-icon {
  font-size: 50px;
}

/* Additional global styles */
.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.carousel .testimonial-item, .carousel .contact-detail-item {
  background: rgba(255, 255, 255, 0.7); /* Semi-transparent white */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.carousel .testimonial-text, .carousel .contact-detail-text {
  font-size: 1.2rem;
  color: #000;
  font-weight: bold; /* Bold text */
}

.carousel .testimonial-author, .carousel .contact-detail-title {
  font-size: 1rem;
  font-weight: bold;
  color: #000;
}

.carousel-item {
  text-align: center;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  filter: invert(1); /* Invert color to white */
}

.carousel-control-next-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23000" viewBox="0 0 8 8"><path d="M4.5 0L3.5 1 6.5 4 3.5 7 4.5 8l5-4-5-4z"/></svg>');
}

.carousel-control-prev-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23000" viewBox="0 0 8 8"><path d="M3.5 0L2.5 1 5.5 4 2.5 7 3.5 8l5-4-5-4z"/></svg>');
}

.cta-section h2 {
  margin-bottom: 1.5rem;
}

.cta-section .btn-success {
  background-color: #007bff;
  border-color: #007bff;
}

.cta-section .btn-success:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}
