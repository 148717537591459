.footer {
  padding: 20px 0; /* Reduced padding */
  background-color: #ffffff; /* White background color for the entire footer */
  color: #000000; /* Black text color */
}

.footer .footer-box {
  background-color: #ffffff; /* White background color for the boxes */
  color: #000000; /* Black text color */
  border-radius: 8px;
  padding: 15px; /* Reduced padding */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Softer shadow */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  text-align: center; /* Ensure text is centered in all boxes */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center-align content within the box */
  max-width: 450px; /* Set maximum width to reduce the size */
  margin: 0 auto; /* Center align */
}

.footer .footer-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Enhanced shadow effect on hover */
}

.footer .footer-box h5 {
  margin-bottom: 10px; /* Reduced margin */
  font-size: 1.2rem; /* Slightly smaller font size */
  color: #000000; /* Black color for headings */
}

.footer .footer-box p {
  margin: 5px 0; /* Reduced margin */
  line-height: 1.5;
  color: #000000; /* Black text color */
}

.footer .footer-box a {
  color: #007bff; /* Blue color for links */
  text-decoration: none;
  transition: color 0.3s;
}

.footer .footer-box a:hover {
  color: #0056b3; /* Darker blue on hover */
  text-decoration: underline;
}

.footer .social-icons {
  margin-top: 10px; /* Reduced space between the contact info and social icons */
  display: flex;
  justify-content: center; /* Centers social icons horizontally */
  gap: 10px; /* Adds space between the icons */
}

.footer .social-icons .social-link {
  font-size: 20px; /* Reduced icon size */
  margin: 0 8px; /* Reduced margin */
  transition: transform 0.3s ease, color 0.3s ease;
}

.footer .social-icons .social-link.instagram {
  color: #E1306C; /* Instagram official color */
}

.footer .social-icons .social-link.facebook {
  color: #3b5998; /* Facebook official color */
}

.footer .social-icons .social-link.instagram:hover,
.footer .social-icons .social-link.facebook:hover {
  transform: scale(1.2);
  color: #007bff; /* Blue color on hover for both icons */
}

.footer-bottom {
  background-color: #f8f9fa; /* Light grey background color for the bottom section */
  color: #000000; /* Black text color */
  padding: 8px 0; /* Reduced padding */
  font-size: 0.85rem; /* Slightly smaller font size */
  border-top: 1px solid #e9ecef; /* Subtle border at the top */
}

@media (max-width: 767.98px) {
  .footer .footer-box {
    margin-bottom: 20px;
    text-align: center;
  }

  .footer .social-icons {
    justify-content: center;
  }
}
